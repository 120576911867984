* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

:root {
  --primary: #ffd900a6;
  --black: #000000;
  --blackLighter: #9e9e9e;
  --background: #141414;
  --grayLight: #f5f5f5;
  --grayMedium: #e5e5e5;
  --white: #ffffff;
  --frontEnd: #6bd1ff;
  --backEnd: #00c86f;
}

html,
body {
  background-color: #141414;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

#root {
  min-height: calc(100vh - var(--bodyPaddingTop));
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}
